import type { ReferenceInputProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { PractitionerReferenceInput } from './PractitonerReference/PractitionerReference'
import { LocationReferenceInput } from './LocationReference'

export default function ReferenceInput(props: ReferenceInputProps) {
  const { referenceResource } = props

  switch (referenceResource) {
    case 'Practitioner':
      return <PractitionerReferenceInput {...props} />
    case 'Location':
      return <LocationReferenceInput {...props} />
    default:
      return null
  }
}
