import {
  Code,
  CodeableConcept,
  HumanName
} from '@healthnz-ult/fhir-engine-renderer/types'

export function CodeableConceptToString({
  coding = [],
  text
}: CodeableConcept): string | undefined {
  if (text) return text

  const displayStrings = coding
    .map(c => c.display)
    .filter((d): d is string => d !== undefined)

  if (displayStrings[0] !== undefined) {
    return displayStrings[0]
  }

  const codes = coding
    .map(c => c.code)
    .filter((c): c is Code => c !== undefined)

  return codes[0]
}

export function humanNameParts(
  name: HumanName | undefined
): [] | [string] | [string, string] {
  if (!name) return []

  const { family, given } = name

  if (family && given) {
    return [family, given.join(' ')]
  }

  if (!family && given) {
    return [given.join(' ')]
  }

  if (family && !given) {
    return [family]
  }

  return []
}
