import {
  Button,
  Icon,
  InputField,
  InputFieldStyles,
  InputMessage,
  Text
} from '@te-whatu-ora/anatomic'
import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useRef,
  useState
} from 'react'
import 'components/fhirEngine/referenceInput/styles.css'
import { ReferenceInputMethodProps } from '../types'

interface GetInputProps<T extends object> extends ReferenceInputMethodProps<T> {
  getById: (id: string) => Promise<T | undefined>
  icon?: React.ReactNode | undefined
  placeholder?: string | undefined
}

export default function GetInput<T extends object>({
  error,
  getById,
  inputId,
  onAdd,
  onBlur,
  onFocus,
  required,
  RenderResource,
  onRemove,
  resource,
  isLoading,
  icon,
  placeholder
}: GetInputProps<T>) {
  const buttonRef = useRef<HTMLButtonElement>(null)

  const [inputValue, setInputValue] = useState<string>('')
  const [privateError, setPrivateError] = useState<string>()
  const [queryLoading, setQueryLoading] = useState<boolean>(false)

  useEffect(() => {
    if (privateError) {
      const timeout = setTimeout(() => setPrivateError(undefined), 5000)

      return () => clearTimeout(timeout)
    }
  }, [privateError])

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value)
  }

  function onSearch() {
    setQueryLoading(true)
    getById(inputValue)
      .then(result => {
        if (!result) throw new Error('No result found')
        onAdd(result)
      })
      .catch((e: Error) => setPrivateError(e.message))
      .finally(() => setQueryLoading(false))
  }

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    // Don't trigger the onBlur if the search button is clicked
    if (e.relatedTarget === buttonRef.current) return
    onBlur()
    onSearch()
  }

  if (isLoading || queryLoading) {
    return (
      <>
        <div
          className={InputFieldStyles.inputBase}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.75rem',
            marginBottom: '0.25rem'
          }}
        >
          {icon && <div style={{ flexShrink: 0 }}>{icon}</div>}
          <Text color='neutral50'>Loading...</Text>
        </div>

        <InputMessage errorMessage={privateError ?? error} />
      </>
    )
  }

  if (resource) {
    return (
      <>
        <div
          className={InputFieldStyles.inputBase}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.75rem',
            marginBottom: '0.25rem',
            height: 'unset',
            minHeight: '56px',
            paddingRight: '0.4rem'
          }}
        >
          {icon && <div style={{ flexShrink: 0 }}>{icon}</div>}
          <RenderResource resource={resource} inputValue={inputValue} />
          <button
            type='button'
            style={{
              marginLeft: 'auto',
              appearance: 'none',
              border: 'none',
              backgroundColor: 'transparent',
              cursor: 'pointer'
            }}
            onClick={onRemove}
          >
            <Icon icon='cross' variant='tagIcon' color='neutral25' />
          </button>
        </div>

        <InputMessage errorMessage={privateError ?? error} />
      </>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', marginBottom: '0.25rem' }}>
        <div style={{ position: 'relative', width: '100%' }}>
          <InputField
            className='reference-input__input-field'
            error={!!error}
            name={inputId}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={onFocus}
            required={required}
            type='text'
            value={inputValue}
            style={{ paddingLeft: '3.25rem' }}
            placeholder={placeholder}
          />
          {icon && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                left: '1.05rem'
              }}
            >
              {icon}
            </div>
          )}
        </div>

        <div style={{ width: '60px', flexShrink: 0 }}>
          <Button
            className='reference-input__reference-button'
            icon='search'
            onClick={onSearch}
            ref={buttonRef}
            variant='primary'
          />
        </div>
      </div>

      <InputMessage errorMessage={privateError ?? error} />
    </>
  )
}
