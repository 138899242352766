import { Box, Text } from '@te-whatu-ora/anatomic'
import { ReferenceInputMethodProps } from './types'

export interface SearchMethodToggleProps<T extends object> {
  searchMethod: number
  onChange: (value: number) => void
  inputs: {
    label: string
    component: (props: ReferenceInputMethodProps<T>) => React.ReactNode
  }[]
}

export function SearchMethodToggle<T extends object>({
  searchMethod,
  onChange,
  inputs
}: SearchMethodToggleProps<T>) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '0.25rem'
      }}
    >
      Search By:{' '}
      <Box
        borderColor='primary100'
        borderWidth='small'
        borderRadius='inputs'
        style={{ display: 'flex', overflow: 'hidden' }}
      >
        {inputs.map((inputMethod, index) => (
          <button
            type='button'
            onClick={() => onChange(index)}
            style={{
              appearance: 'none',
              padding: 0,
              backgroundColor: 'transparent',
              border: 'none'
            }}
          >
            <Box
              backgroundColor={
                searchMethod === index ? 'primary100' : undefined
              }
              color={searchMethod === index ? 'neutral0' : undefined}
              style={{
                padding: '0.25rem 1rem',
                flexBasis: '0',
                flexGrow: '1'
              }}
              textAlign='center'
            >
              <Text size='xsmall'>{inputMethod.label}</Text>
            </Box>
          </button>
        ))}
      </Box>
    </div>
  )
}
