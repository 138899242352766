import { apiEndpointUrl, xApiKey } from 'env'
import { GenericError, NotFoundError, handleNetworkResponse } from 'lib/error'

export interface Metadata {
  approvalWorkflow?: boolean
  authenticationMode: string
  cacheExpiresAt: number
  maintenanceMode: 'true' | 'false'
  name: string
  questionnaire: {
    identifier: string
    version: string
  }
}

export function getMetadata(questionnaireId: string): Promise<Metadata> {
  return fetch(
    `${apiEndpointUrl}/v1/questionnaire/${questionnaireId}/metadata`,
    {
      method: 'get',
      headers: {
        'x-api-key': xApiKey
      }
    }
  )
    .then(handleNetworkResponse)
    .then(res => res.json())
    .catch((e: unknown) => {
      if (e instanceof NotFoundError) throw e
      throw new GenericError()
    })
}
