import { Tag } from '@te-whatu-ora/anatomic'
import { Practitioner } from './types'

export interface PractitionerDeceasedProps {
  practitioner: Practitioner
}

export function PractitionerDeceased({
  practitioner
}: PractitionerDeceasedProps) {
  const isDeceased =
    practitioner.extension?.find(
      e => e.url === 'http://hl7.org.nz/fhir/StructureDefinition/death-date'
    ) !== undefined

  if (!isDeceased) return

  return <Tag label='deceased' />
}
