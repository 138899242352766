import { Navigate, useParams } from 'react-router-dom'
import { WithAuthProvider } from 'contexts/auth/auth'
import { QuestionnaireWrapper } from 'components/questionnaireWrapper'
import { useQuestionnaireSession } from 'hooks/useQuestionnaireSession'
import { Helmet } from 'react-helmet'
import { Loader } from 'components/loader'
import { useEffect, useState } from 'react'
import * as defaultComponents from 'components/fhirEngine'
import * as embeddedComponents from 'components/embeddedComponents'
import { SubmitStateProvider } from 'contexts/submitState/submitState'
import ResizeObserver from 'resize-observer-polyfill'

export interface EmbeddedQuestionnairePageProps {
  authToken: string
}

export function EmbeddedQuestionnairePage({
  authToken
}: EmbeddedQuestionnairePageProps) {
  const { questionnaireId } = useParams()
  const [error, setError] = useState<unknown>()

  const {
    error: sessionError,
    loading,
    data,
    maintenanceMode,
    approvalWorkflow
  } = useQuestionnaireSession({
    questionnaireId: questionnaireId ?? '',
    authToken
  })

  useEffect(() => {
    if (error || sessionError) {
      const e = error ?? sessionError
      const message =
        // eslint-disable-next-line no-nested-ternary
        e instanceof Error ? e.message : typeof e === 'string' ? e : 'Error'

      window.parent.postMessage(
        {
          type: 'error',
          message
        },
        '*' // allowing any target domain because we don't send sensitive data
      )
    }
  }, [error, sessionError])

  useEffect(() => {
    // using lastResize to dedupe resize events
    // we don't need the state to be accessible in React so we can use a variable + closure instead of a useState
    // this is useful as there's a delay with useState which could allow duplicate events to be sent while it updates
    let lastResize = 0

    const resizeObserver = new ResizeObserver(entries => {
      if (entries[0].target) {
        // adding 1 extra pixel helps avoid rounding issues
        const resizeHeight = entries[0].target.scrollHeight + 1

        if (resizeHeight === lastResize) return
        lastResize = resizeHeight

        window.parent.postMessage(
          {
            type: 'resize',
            height: resizeHeight
          },
          '*' // allowing any target domain because we don't send sensitive data
        )
      }
    })

    resizeObserver.observe(document.body)

    return () => resizeObserver.unobserve(document.body)
  }, [])

  if (loading) return <Loader message='Loading' />
  if (sessionError ?? error) throw sessionError ?? error
  if (maintenanceMode) return <Navigate to='/embed/maintenance' />

  function handleSubmit() {
    window.parent.postMessage(
      { type: 'submit' },
      '*' // allowing any target domain because we don't send sensitive data
    )
  }

  return (
    <>
      <Helmet>
        <title>{data.questionnaire.title}</title>
        <meta name='description' content={data.questionnaire.description} />
        <meta property='og:title' content={data.questionnaire.title} />
        <meta
          property='og:description'
          content={data.questionnaire.description}
        />
      </Helmet>
      <SubmitStateProvider>
        <QuestionnaireWrapper
          customComponents={{
            ...defaultComponents,
            ...embeddedComponents
          }}
          data={data}
          onError={setError}
          onSubmit={handleSubmit}
          approvalWorkflow={approvalWorkflow}
        />
      </SubmitStateProvider>
    </>
  )
}

export default WithAuthProvider(EmbeddedQuestionnairePage)
