import { Text } from '@te-whatu-ora/anatomic'
import { Show } from 'components/show'
import {
  Practitioner,
  RegistrationStatusExtension,
  RegistrationStatusStatusExtension
} from './types'
import { CodeableConceptToString } from './utils'

function qualificationIsCurrent(
  qualification: NonNullable<Practitioner['qualification']>[number]
) {
  const statusExtension = qualification.extension.filter(
    (extension): extension is RegistrationStatusExtension =>
      extension.url ===
      'http://hl7.org.nz/fhir/StructureDefinition/registration-status-code'
  )

  if (statusExtension.length <= 0) return false

  const status = statusExtension[0].extension.find(
    (e): e is RegistrationStatusStatusExtension => e.url === 'status'
  )?.valueCodeableConcept

  if (status === undefined) return false

  return status.coding?.some(coding => coding.code === 'current') ?? false
}

export type PractitionerQualificationProps = Pick<Practitioner, 'qualification'>

export function PractitionerQualification({
  qualification
}: PractitionerQualificationProps) {
  const activeQualifications =
    qualification?.filter(qualificationIsCurrent) ?? []

  return (
    <Text style={{ lineHeight: 1 }} color='neutral50' size='small'>
      Registration authority:{' '}
      <Show
        when={activeQualifications.length > 0}
        fallback={
          <Text color='error100' size='small' as='span'>
            Warning, not currently registered with any registration authority
          </Text>
        }
      >
        {activeQualifications
          .map(qual => CodeableConceptToString(qual.code))
          .join(', ')}
      </Show>
    </Text>
  )
}
