import { Navigate, useParams } from 'react-router-dom'
import { WithAuthProvider } from 'contexts/auth/auth'
import { QuestionnaireWrapper } from 'components/questionnaireWrapper'
import { useQuestionnaireSession } from 'hooks/useQuestionnaireSession'
import { Loader } from '@te-whatu-ora/anatomic'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { SubmitStateProvider } from 'contexts/submitState/submitState'

interface QuestionnairePageProps {
  authToken: string
}

export function QuestionnairePage({ authToken }: QuestionnairePageProps) {
  const { questionnaireId } = useParams()
  const [error, setError] = useState<unknown>()

  const {
    error: sessionError,
    loading,
    data,
    maintenanceMode,
    approvalWorkflow
  } = useQuestionnaireSession({
    questionnaireId: questionnaireId ?? '',
    authToken
  })

  if (loading) return <Loader message='Loading' />
  if (sessionError ?? error) throw sessionError ?? error
  if (maintenanceMode) return <Navigate to='/maintenance' />

  return (
    <>
      <Helmet>
        <title>{data.questionnaire.title}</title>
        <meta name='description' content={data.questionnaire.description} />
        <meta property='og:title' content={data.questionnaire.title} />
        <meta
          property='og:description'
          content={data.questionnaire.description}
        />
      </Helmet>
      <SubmitStateProvider>
        <QuestionnaireWrapper
          data={data}
          onError={setError}
          approvalWorkflow={approvalWorkflow}
        />
      </SubmitStateProvider>
    </>
  )
}

export default WithAuthProvider(QuestionnairePage)
