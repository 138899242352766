import type {
  Reference,
  ReferenceInputProps
} from '@healthnz-ult/fhir-engine-renderer/types'
import { useEffect, useState } from 'react'
import { InputLabel } from '../inputLabel'
import {
  SearchMethodToggle,
  SearchMethodToggleProps
} from './SearchMethodToggle'
import { RenderResourceProps } from './types'

export interface TypedReferenceInputProps<T extends object>
  extends Pick<
    ReferenceInputProps,
    | 'error'
    | 'inputId'
    | 'onBlur'
    | 'onFocus'
    | 'referenceResource'
    | 'required'
    | 'labelComponent'
    | 'label'
    | 'onChange'
    | 'value'
  > {
  RenderResource: (props: RenderResourceProps<T>) => React.ReactNode
  toReference: (resource: T) => Reference
  fromReference: (reference: Reference) => Promise<T | undefined>
  inputs: SearchMethodToggleProps<T>['inputs']
}

export function TypedReferenceInput<T extends object>({
  inputId,
  onBlur,
  onChange,
  required,
  error,
  label,
  labelComponent,
  onFocus,
  value,
  RenderResource,
  fromReference,
  inputs,
  toReference
}: TypedReferenceInputProps<T>) {
  const [resource, setResource] = useState<T>()

  useEffect(() => {
    if (!!value && resource === undefined) {
      fromReference(value).then(setResource)
    }
  }, [fromReference, value, resource])

  const [searchMethod, setSearchMethod] = useState<number>(0)

  function handleChange(newValue: T | undefined) {
    setResource(newValue)
    onChange(newValue ? toReference(newValue) : null)
  }

  const Input = inputs[searchMethod].component

  return (
    <>
      {/* We only display the required '*' if the label doesn't have a component, or it'll mess with the layout. Include the '*' in the labelComponent. */}
      <InputLabel labelComponent={labelComponent ?? label} />
      {inputs.length > 1 && (
        <SearchMethodToggle
          searchMethod={searchMethod}
          inputs={inputs}
          onChange={setSearchMethod}
        />
      )}
      <Input
        inputId={inputId}
        onBlur={onBlur}
        onFocus={onFocus}
        onAdd={handleChange}
        onRemove={() => handleChange(undefined)}
        required={required}
        RenderResource={RenderResource}
        error={error}
        resource={resource}
        isLoading={!!value && !resource}
      />
    </>
  )
}
