import debounce from 'lodash.debounce'
import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import customComponents from 'components/fhirEngine/select/components'
import 'components/fhirEngine/referenceInput/styles.css'
import { Box, InputMessage } from '@te-whatu-ora/anatomic'
import {
  GroupBase,
  OptionsOrGroups,
  SingleValue,
  SingleValueProps
} from 'react-select'
import { ReferenceInputMethodProps, RenderResourceProps } from '../types'

interface ReferenceOption<T> {
  label: string
  value: T
}

interface SearchInputProps<T extends object>
  extends ReferenceInputMethodProps<T> {
  onSearch: (name: string) => Promise<T[]>
  resourceToLabel: (resource: T) => string
  icon?: React.ReactNode
  placeholder?: string | undefined
}

function SingleValueComponent<T extends object>(
  RenderResource: (props: RenderResourceProps<T>) => React.ReactNode
) {
  return ({
    data
  }: SingleValueProps<ReferenceOption<T>, false, GroupBase<any>>) => {
    if (!data.value) return null
    return (
      <Box style={{ gridArea: '1 / 1 / 2 / 3' }}>
        <RenderResource resource={data.value} />
      </Box>
    )
  }
}

export default function SearchInput<T extends object>({
  onSearch,
  inputId,
  onBlur,
  onAdd,
  required,
  onFocus,
  RenderResource,
  resource,
  onRemove,
  error,
  icon,
  resourceToLabel,
  placeholder,
  isLoading
}: SearchInputProps<T>) {
  const [internalValue, setInternalValue] = useState<string>('')

  async function loadOptions(
    inputValue: string,
    callback: (
      options: OptionsOrGroups<ReferenceOption<T>, GroupBase<any>>
    ) => void
  ) {
    const results = await onSearch(inputValue).catch(() => [] as T[])
    callback(
      results.map(result => ({
        label: resourceToLabel(result),
        value: result
      })) as OptionsOrGroups<ReferenceOption<T>, GroupBase<any>>
    )
  }

  const debouncedLoadOptions = debounce(loadOptions, 500)

  function handleLoadOptions(
    inputValue: string,
    callback: (
      options: OptionsOrGroups<ReferenceOption<T>, GroupBase<any>>
    ) => void
  ) {
    debouncedLoadOptions(inputValue, callback)
  }

  function handleChange(option: SingleValue<ReferenceOption<T | undefined>>) {
    const value = option?.value

    if (value) {
      onAdd(value)
    } else {
      onRemove()
    }
  }

  return (
    <>
      <div>
        <div style={{ marginBottom: '0.25rem', position: 'relative' }}>
          {icon && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '1.05rem',
                zIndex: '10',
                transform: 'translateY(-50%)'
              }}
            >
              {icon}
            </div>
          )}
          <AsyncSelect<ReferenceOption<T>>
            components={{
              ...customComponents,
              SingleValue: SingleValueComponent(RenderResource),
              DropdownIndicator: undefined
            }}
            cacheOptions
            id={inputId}
            isClearable={!!resource}
            isSearchable={!resource}
            menuIsOpen={
              // keep closed if resource already selected or search string is too short to query on
              resource || internalValue.length < 3 ? false : undefined
            }
            onInputChange={v => setInternalValue(v)}
            isMulti={false}
            loadOptions={handleLoadOptions}
            placeholder={isLoading ? 'Loading...' : placeholder}
            isDisabled={isLoading}
            onBlur={onBlur}
            onFocus={onFocus}
            value={resource ? { label: '123', value: resource } : undefined}
            onChange={handleChange}
            required={required}
            styles={
              icon
                ? {
                    valueContainer: base => ({
                      ...base,
                      paddingBlock: 0,
                      paddingLeft: '2.25rem'
                    })
                  }
                : undefined
            }
          />
        </div>
      </div>
      <InputMessage errorMessage={error} />
    </>
  )
}
