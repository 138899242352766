import { handleNetworkResponse, NotFoundError } from 'lib/error'
import { xApiKey, apiEndpointUrl } from 'env'
import { Text } from '@te-whatu-ora/anatomic'
import {
  Reference,
  ReferenceInputProps
} from '@healthnz-ult/fhir-engine-renderer/types'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { useAuth } from 'contexts/auth/auth'
import { Bundle, RenderResourceProps } from './types'
import SearchInput from './searchInput'
import { TypedReferenceInput } from './TypedReferenceInput'
import { SearchMethodToggleProps } from './SearchMethodToggle'

interface Location {
  resourceType: 'Location'
  id: string
  name: string
  alias?: string[]
}

interface OperationOutcome {
  resourceType: 'OperationOutcome'
}

async function fromReference(
  authToken: string,
  questionnaireId: string | undefined,
  reference: Reference
): Promise<Location | undefined> {
  const locationId = reference.reference?.split('/')[1]

  if (!locationId) return undefined

  return fetch(
    `${apiEndpointUrl}/v1/hip/${questionnaireId}/location/${locationId}`,
    {
      method: 'get',
      headers: {
        Authorization: authToken,
        'x-api-key': xApiKey
      }
    }
  )
    .then(handleNetworkResponse)
    .then(res => res?.json() as Promise<Location | OperationOutcome>)
    .then(res => {
      if (res.resourceType === 'OperationOutcome') throw new NotFoundError()
      return res
    })
    .catch(() => undefined)
}

async function searchLocation(
  authToken: string,
  questionnaireId: string | undefined,
  name: string
): Promise<Location[]> {
  return fetch(
    `${apiEndpointUrl}/v1/hip/${questionnaireId}/location/search?name=${name}`,
    {
      method: 'get',
      headers: {
        Authorization: authToken,
        'x-api-key': xApiKey
      }
    }
  )
    .then(handleNetworkResponse)
    .then(res => res?.json())
    .then((bundle: Bundle<Location> | undefined) => {
      return bundle?.entry?.map(entry => entry.resource) ?? []
    })
    .catch(() => {
      throw new Error('An error occurred while searching. Please try again.')
    })
}

function RenderResource({ resource }: RenderResourceProps<Location>) {
  return (
    <div style={{ paddingBlock: '0.5rem' }}>
      <Text
        style={{ lineHeight: 1.25, marginBottom: '0.2rem' }}
        weight='medium'
      >
        {resource.name ?? <Text color='error100'>No recorded name</Text>}
      </Text>
      <Text style={{ lineHeight: 1 }} color='neutral50' size='small'>
        ID: {resource.id ?? '-'}
      </Text>
      <Text style={{ lineHeight: 1 }} color='neutral50' size='small'>
        {resource.alias?.length && (
          <>Other names: {resource.alias.join(', ')}</>
        )}
      </Text>
    </div>
  )
}

function toReference(resource: Location): Reference {
  return {
    display: resource.name,
    reference: `Location/${resource.id}`
  }
}

function makeInputs(
  authToken: string,
  questionnaireId: string | undefined
): SearchMethodToggleProps<Location>['inputs'] {
  return [
    {
      label: 'LocationName',
      component: props => (
        <SearchInput
          {...props}
          placeholder='Search for facility by name'
          onSearch={async name =>
            searchLocation(authToken, questionnaireId, name)
          }
          resourceToLabel={resource => resource.name ?? '-'}
        />
      )
    }
  ]
}

export function LocationReferenceInput(props: ReferenceInputProps) {
  const authToken = useAuth()
  const { questionnaireId } = useParams()

  const inputs = useMemo(
    () => makeInputs(authToken, questionnaireId),
    [authToken, questionnaireId]
  )

  return (
    <TypedReferenceInput
      {...props}
      RenderResource={RenderResource}
      fromReference={async reference =>
        fromReference(authToken, questionnaireId, reference)
      }
      toReference={toReference}
      inputs={inputs}
    />
  )
}
