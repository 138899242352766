import { Text } from '@te-whatu-ora/anatomic'
import { Show } from 'components/show'
import { Practitioner } from './types'
import { humanNameParts } from './utils'

export type PractitionerNameProps = Pick<Practitioner, 'name'>

export function PractitionerName({ name }: PractitionerNameProps) {
  const officialName = name?.find(n => n.use === 'official')
  const [officialFamily, officialGiven] = humanNameParts(officialName)

  return (
    <Text style={{ lineHeight: 1.25 }} weight='medium'>
      <Show
        when={officialFamily}
        fallback={
          <Text color='error100' as='span'>
            No recorded Official name
          </Text>
        }
      >
        <Text weight='bold' as='span' display='inline'>
          {officialFamily}
        </Text>
        <Show when={officialGiven}>, {officialGiven}</Show>
      </Show>
    </Text>
  )
}

export function PractitionerUsualName({ name }: PractitionerNameProps) {
  const usualName = name?.find(n => n.use === 'usual')
  const [usualFamily, usualGiven] = humanNameParts(usualName)

  if (!usualFamily) return

  return (
    <Text
      style={{ lineHeight: 1.25 }}
      color='neutral50'
      weight='medium'
      size='small'
      display='block'
    >
      <Text weight='bold' as='span' display='inline'>
        {usualFamily}
      </Text>
      <Show when={usualGiven}>, {usualGiven}</Show>
    </Text>
  )
}
