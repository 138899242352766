import { Text } from '@te-whatu-ora/anatomic'
import { Practitioner } from './types'

function isRedacted(practitioner: Practitioner) {
  const security = practitioner.meta?.security

  const redacted = security?.find(
    s =>
      s.system ===
        'http://terminology.hl7.org/CodeSystem/v3-ObservationValue' &&
      s.code === 'REDACTED'
  )

  const restricted = security?.find(
    s =>
      s.system === 'http://terminology.hl7.org/CodeSystem/v3-Confidentiality' &&
      s.code === 'R'
  )

  return redacted || restricted
}

export interface PractitionerRedactionProps {
  practitioner: Practitioner
}

export function PractitionerRedaction({
  practitioner
}: PractitionerRedactionProps) {
  if (isRedacted(practitioner))
    return (
      <Text color='error100' size='small'>
        Some practitioner details may be redacted
      </Text>
    )

  return undefined
}
