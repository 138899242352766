import { ErrorTemplate } from './Template'

export interface DefaultErrorProps {
  error: unknown
  canQuit?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function DefaultError({ error, canQuit = false }: DefaultErrorProps) {
  if (error instanceof Error) return <ErrorTemplate message={error.message} />
  if (typeof error === 'string') return <ErrorTemplate message={error} />
  return <ErrorTemplate />
}
