import { handleNetworkResponse, NotFoundError } from 'lib/error'
import { xApiKey, apiEndpointUrl } from 'env'
import { Icon } from '@te-whatu-ora/anatomic'
import {
  Reference,
  ReferenceInputProps
} from '@healthnz-ult/fhir-engine-renderer/types'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { useAuth } from 'contexts/auth/auth'
import { isValidHPIIdentifier } from '../utils'
import { Bundle } from '../types'
import GetInput from '../getInput'
import SearchInput from '../searchInput'
import { TypedReferenceInput } from '../TypedReferenceInput'
import { SearchMethodToggleProps } from '../SearchMethodToggle'
import { Practitioner } from './types'
import { humanNameParts } from './utils'
import { RenderPractitioner } from './RenderPractitioner'

interface OperationOutcome {
  resourceType: 'OperationOutcome'
}

async function fetchPractitioner(
  authToken: string,
  questionnaireId: string | undefined,
  cpn: string
): Promise<Practitioner | undefined> {
  if (!cpn) throw new Error('CPN is required')
  if (!isValidHPIIdentifier(cpn))
    throw new Error(
      'The provided HPI number is invalid. Please follow the appropriate format for CPN, HPI ORG ID, or HPI FAC ID.'
    )

  return fetch(
    `${apiEndpointUrl}/v1/hip/${questionnaireId}/practitioner/${cpn}`,
    {
      method: 'get',
      headers: {
        Authorization: authToken,
        'x-api-key': xApiKey
      }
    }
  )
    .then(handleNetworkResponse)
    .then(res => res?.json() as Promise<Practitioner | OperationOutcome>)
    .then(res => {
      if (res.resourceType === 'OperationOutcome') throw new NotFoundError()
      return res
    })
    .catch(e => {
      if (e instanceof NotFoundError)
        throw new Error('Could not find a Practitioner with that CPN.')

      throw new Error('An error occurred while searching. Please try again.')
    })
}

async function searchPractitioner(
  authToken: string,
  questionnaireId: string | undefined,
  name: string
): Promise<Practitioner[]> {
  return fetch(
    `${apiEndpointUrl}/v1/hip/${questionnaireId}/practitioner/search?name=${name}`,
    {
      method: 'get',
      headers: {
        Authorization: authToken,
        'x-api-key': xApiKey
      }
    }
  )
    .then(handleNetworkResponse)
    .then(res => res?.json())
    .then((bundle: Bundle<Practitioner> | undefined) => {
      return bundle?.entry?.map(entry => entry.resource) ?? []
    })
    .catch(() => {
      throw new Error('An error occurred while searching. Please try again.')
    })
}

function toReference(resource: Practitioner): Reference {
  return {
    display: humanNameParts(resource.name?.[0]).join(' '),
    reference: `Practitioner/${resource.id}`
  }
}

async function fromReference(
  authToken: string,
  questionnaireId: string | undefined,
  reference: Reference
): Promise<Practitioner | undefined> {
  const cpn = reference.reference?.split('/')[1]
  if (!cpn) return undefined

  return fetchPractitioner(authToken, questionnaireId, cpn)
}

function makeInputs(
  authToken: string,
  questionnaireId: string | undefined
): SearchMethodToggleProps<Practitioner>['inputs'] {
  return [
    {
      label: 'CPN',
      component: props => (
        <GetInput
          {...props}
          icon={
            <Icon icon='person' variant='functionalIcons' color='primary100' />
          }
          getById={async cpn =>
            fetchPractitioner(authToken, questionnaireId, cpn)
          }
          placeholder='CPN'
        />
      )
    },
    {
      label: 'Name',
      component: props => (
        <SearchInput
          {...props}
          icon={
            <Icon icon='person' variant='functionalIcons' color='primary100' />
          }
          onSearch={async name =>
            searchPractitioner(authToken, questionnaireId, name)
          }
          resourceToLabel={resource =>
            humanNameParts(resource.name?.[0]).join(' ')
          }
          placeholder='Search by Name'
        />
      )
    }
  ]
}

export function PractitionerReferenceInput(props: ReferenceInputProps) {
  const authToken = useAuth()
  const { questionnaireId } = useParams()

  const inputs = useMemo(
    () => makeInputs(authToken, questionnaireId),
    [authToken, questionnaireId]
  )

  return (
    <TypedReferenceInput
      {...props}
      RenderResource={RenderPractitioner}
      fromReference={async reference =>
        fromReference(authToken, questionnaireId, reference)
      }
      toReference={toReference}
      inputs={inputs}
    />
  )
}
