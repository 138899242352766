import { Box, Text } from '@te-whatu-ora/anatomic'
import { Show } from 'components/show'
import { RenderResourceProps } from '../types'
import { Practitioner } from './types'
import { PractitionerName, PractitionerUsualName } from './PractitionerName'
import { PractitionerQualification } from './PractitionerQualification'
import { PractitionerRedaction } from './PractitionerRedaction'
import { PractitionerDeceased } from './PractitionerDeceased'

export function RenderPractitioner({
  resource,
  inputValue
}: RenderResourceProps<Practitioner>) {
  const officialId = resource.identifier.filter(
    id =>
      id.system === 'https://standards.digital.health.nz/ns/hpi-person-id' &&
      id.use === 'official'
  )[0]?.value

  const mismatch = inputValue && inputValue !== officialId

  return (
    <Box paddingY='xsmall' flexGrow={1} width='full'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          flexWrap: 'wrap'
        }}
      >
        <PractitionerName name={resource.name} />
        <PractitionerDeceased practitioner={resource} />
      </div>

      <PractitionerUsualName name={resource.name} />
      <p style={{ lineHeight: 1 }}>
        <Text
          as='span'
          style={{ lineHeight: 1 }}
          color='neutral50'
          size='small'
          display='inline'
        >
          {officialId ?? '—'}
        </Text>
        <Show when={mismatch}>
          <Text
            as='span'
            color='error100'
            size='small'
            style={{ lineHeight: 1 }}
            display='inline'
          >
            {' '}
            (requested CPN {inputValue} is no longer live)
          </Text>
        </Show>
      </p>
      <PractitionerQualification qualification={resource.qualification} />
      <PractitionerRedaction practitioner={resource} />
    </Box>
  )
}
